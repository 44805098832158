import user from 'types/user';

export default async function() {
    const usersFetched: Array<user> = [];
    const config = useRuntimeConfig();
    const { data: auth0UserData } = await useFetch(await config.public.apiBase + '/issues/users/auth0users');
    const assigneeList = auth0UserData?._rawValue;
    for (const auth0User of assigneeList)
        usersFetched.push({
            name: auth0User.name,
            email: auth0User.email,
            avatar: auth0User.picture
        });
    return usersFetched;
}
